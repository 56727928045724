import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"

// Utils
import { sanitizeTitle } from "../../utils/sanitize"

// Layout Components
import Layout from "../../components/layout/index"
import SEO from "../../components/seo/index"
import Container from "../../components/container"

// Components
import Pagination from "../../components/pagination/index"
import Posts from "../../components/post/grid"

export default ({
  data: {
    wordpressWpUsers: author,
    allWordpressPost: { nodes: posts },
  },
  pageContext: { pageNumber, numberOfPages },
}) => {
  const [currentPage, setCurrentPage] = useState(pageNumber)

  const slug = `author/${author.slug}`

  const pageTitle =
    pageNumber === 0
      ? `${author.name}, Author at Proof Blog`
      : `${author.name}, Author at Proof Blog - Page ${pageNumber + 1} of ${numberOfPages}`

  const avatar = 
    author.mpp_avatar.full && author.mpp_avatar.full.localFile && author.mpp_avatar.full.localFile.childImageSharp
    ? author.mpp_avatar.full.localFile.childImageSharp.fixed
    : false

  return (
    <Layout pathPrefix={slug}>
      <SEO
        title={pageTitle}
        slug={slug}
        paged={pageNumber !== 0 && pageNumber + 1}
      />
      <Container
        sm={`
          grid-row-gap: 1.5rem;
          padding-top: 1.5rem;
        `}
        lg={`
          padding-top: 3rem;
          grid-row-gap: 3rem;
        `}
      >
        <Header>
          {avatar && <Avatar fixed={avatar} alt={`Avatar of ${author.name}`} />}
          <Content>
            <Title>{author.name}</Title>
            {author.description && (
              <Description>{sanitizeTitle(author.description)}</Description>
            )}
          </Content>
        </Header>
        {posts.length > 0 && (
          <>
            <Posts posts={posts} showAd />
            {numberOfPages > 1 && (
              <Pagination
                slug={slug}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={numberOfPages}
              />
            )}
          </>
        )}
      </Container>
    </Layout>
  )
}

// Styled Components
const Header = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1.5rem;
  @media (min-width: 992px) {
    grid-row-gap: 2rem;
  }
`
const Avatar = styled(Img)`
  border-radius: 50%;
  margin: 0 auto;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 0.375rem;
  text-align: center;
`
const Title = styled.h1`
  font-size: 1.3125rem;
  line-height: 1.33;
`
const Description = styled.p`
  max-width: 21.375rem;
  margin: 0 auto;
  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
`

// Page Query
export const pageQuery = graphql`
  query authorById($id: String!, $skip: Int!, $limit: Int!) {
    wordpressWpUsers(id: { eq: $id }) {
      name
      slug
      description
      mpp_avatar {
        full {
          localFile {
            publicURL
            childImageSharp {
              fixed(height: 92, width: 92, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      sort: {order: DESC, fields: date}
      skip: $skip
      limit: $limit
      filter: { author: { id: { eq: $id } } }
    ) {
      nodes {
        id
        title
        excerpt
        slug
        ...postCategory
        featured_media {
          ...postCardBg
        }
        author {
          name
          slug
          ...authorAvatar
        }
      }
    }
  }
`
